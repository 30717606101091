import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout, showLogin, hideLogin } from "../../actions/auth";
import Login from "../auth/Login";
import Register from "../auth/Register";
import {
  showDropDown,
  hideDropDown,
  showPassChange,
  hidePassChange,
  showProfile,
  hideProfile,
} from "../../actions/user";
import useOnclickOutside from "react-cool-onclickoutside";

const Navbar = ({
  auth: { isAuthenticated, loading, user },
  logout,
  user_dropdown,
  showDropDown,
  hideDropDown,
  showPassChange,
  hidePassChange,
  showProfile,
  hideProfile,
}) => {
  const [isShowingLogin, setIsShowingLogin] = useState(false);
  const toggle = () => {
    setIsShowingLogin(!isShowingLogin);
  };
  const [isUser, setIsUser] = useState(false);
  const toggleUser = () => {
    setIsUser(!isUser);
  };
  const [isShowingRegister, setIsShowingRegister] = useState(false);
  const toggleRegister = () => {
    setIsShowingRegister(!isShowingRegister);
  };

  const ref = useOnclickOutside(() => {
    hideDropDown();
  });

  const [isActive, setisActive] = useState(false);
  const userLinks = (
    <Fragment>
      <nav
        className="navbar is-fixed-top is-white has-shadow"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <a
              onClick={() => setisActive(!isActive)}
              role="button"
              className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
              aria-label="menu"
              aria-expanded="false"
              data-target="mainNavbar"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div
            id="mainNavbar"
            className={`navbar-menu ${isActive ? "is-active" : ""}`}
          >
            <div class="navbar-start">
              <img
                src="assets/img/cloudlogo.png"
                key="assets/img/cloudlogo.png"
                style={{
                  maxHeight: "80px",
                  maxWidth: "105px",
                }}
              ></img>
            </div>

            <div className="navbar-end">
              <div
                class={`dropdown navbar-item ${
                  user_dropdown ? "is-active" : ""
                }`}
                ref={ref}
              >
                <a class="dropdown-trigger">
                  <span
                    class="button signup-button rounded danger-btn raised user-profile"
                    style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    aria-controls="dropdown-menu"
                    onClick={showDropDown}
                  >
                    <span>
                      <i class="fas fa-user"></i>
                    </span>
                    <span class="icon is-small">
                      <i class="fas fa-angle-down" aria-hidden="true"></i>
                    </span>
                  </span>
                </a>
                <div
                  class="dropdown-menu user-profile-dropdown"
                  id="dropdown-menu"
                  role="menu"
                >
                  <div class="dropdown-content">
                    <a class="dropdown-item" onClick={showProfile}>
                      Профайл
                    </a>
                    <a class="dropdown-item" onClick={showPassChange}>
                      Нууц үг солих
                    </a>
                  </div>
                </div>
              </div>

              <a
                className="navbar-item modal-trigger"
                data-modal="reg-modal"
                onClick={() => {
                  logout();
                }}
              >
                <span
                  className="button signup-button rounded is-danger is-outlined raised"
                  style={{}}
                >
                  Гарах
                </span>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </Fragment>
  );

  const guestLinks = (
    <Fragment>
      <nav
        className="navbar is-fixed-top is-white has-shadow"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <a
              onClick={() => setisActive(!isActive)}
              role="button"
              className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
              aria-label="menu"
              aria-expanded="false"
              data-target="mainNavbar"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div
            id="mainNavbar"
            className={`navbar-menu ${isActive ? "is-active" : ""}`}
          >
            <div class="navbar-start">
              <img
                key="assets/img/cloudlogo.png"
                src="assets/img/cloudlogo.png"
                style={{
                  maxHeight: "80px",
                  maxWidth: "105px",
                }}
                className="navbar-logo"
              ></img>
            </div>
            <div className="navbar-end">
              <a
                href="#"
                onClick={() => {
                  toggle();
                }}
                className="navbar-item is-secondary modal-trigger"
                data-modal="auth-modal"
              >
                Нэвтрэх
              </a>
              <a
                className="navbar-item modal-trigger"
                data-modal="reg-modal"
                onClick={() => {
                  toggleRegister();
                }}
              >
                <span
                  className="button signup-button rounded primary-btn raised"
                  style={{ borderColor: "#46bd9b" }}
                >
                  Бүртгүүлэх
                </span>
              </a>
            </div>
          </div>
        </div>
      </nav>
      <Login isShowing={isShowingLogin} hide={toggle} />
      <Register
        isShowingRegister={isShowingRegister}
        hideRegister={toggleRegister}
      />
    </Fragment>
  );

  return (
    <Fragment>
      {!loading && (
        <Fragment>{isAuthenticated ? userLinks : guestLinks}</Fragment>
      )}
    </Fragment>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user_dropdown: state.user.user_dropdown,
});

export default connect(mapStateToProps, {
  logout,
  showDropDown,
  hideDropDown,
  showPassChange,
  hidePassChange,
  showProfile,
  hideProfile,
})(Navbar);
