import { UPLOAD_FILE, UPLOAD_FILE_ERROR, GET_FILE, FILE_ERROR, GET_FILES, FILES_ERROR, DELETE_FILE, CLEAR_FILES, IS_FILE_UPLOADING } from '../actions/types';

const initialState = {
  files: [],
  file: '',
  loading: true,
  uploadingFile: false,
  error: {}
}

export default function(state = initialState, action) {
  const { type, payload } = action

  switch(type) {
    case GET_FILE:
      return {
        ...state,
        file: payload,
        loading: false
      }
    case UPLOAD_FILE:
      return {
        ...state,
        files: [payload, ...state.files],
        loading: false
      }
    case IS_FILE_UPLOADING:
      return {
        ...state,
        uploadingFile: payload
      }
    case DELETE_FILE:
      return {
        ...state,
        files: state.files.filter(file => file._id !== payload),
        loading: false
      }
    case GET_FILES:
      return {
        ...state,
        files: payload,
        laoding: false
      }
    case FILES_ERROR:
    case UPLOAD_FILE_ERROR:
    case FILE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      }
    case CLEAR_FILES: 
      return {
        ...state,
        files: [],
        file: '',
        loading: false
      }
    default:
      return state
  }
}
