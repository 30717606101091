import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import Alert from "../layout/Alert";
import Register from "../auth/Register";
import Login from "../auth/Login";
import Dashboard from "../dashboard/Dashboard";
import NotFound from "../layout/NotFound";
import PrivateRoute from "../routing/PrivateRoute";
import Appbar from "../layout/Appbar";
import Navbar from "../layout/Navbar";
import Landing from "../layout/Landing";
import FileUpload from "../file/FileUpload";
import ReduxToastr from "react-redux-toastr";

const Routes = () => {
  return (
    <Fragment>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <PrivateRoute exact path="/upload" component={FileUpload} />
        <Route component={NotFound}></Route>
      </Switch>
    </Fragment>
  );
};

export default Routes;
