import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Appbar from "./components/layout/Appbar";
import Dashboard from "./components/dashboard/Dashboard";
import Login from "./components/auth/Login";
import Routes from "./components/routing/Routes";
import "./App.css";
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";
//import 'bulma/css/bulma.css';
//import './main.css';
import "typeface-roboto";
import ReduxToastr from "react-redux-toastr";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <Route component={Routes}></Route>
          <ReduxToastr
            timeOut={2000}
            preventDuplicates
            newestOnTop={false}
            position="bottom-center"
            getState={(state) => state.toastr} // This is the default
            transitionIn="fadeIn"
            transitionOut="fadeOut"
          />
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
