import axios from "axios";
import { setAlert } from "./alert";
import {
  UPLOAD_FILE,
  FILE_ERROR,
  GET_FILE,
  UPLOAD_FILE_ERROR,
  GET_FILES,
  FILES_ERROR,
  DELETE_FILE,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_PROFILE,
  SHOW_LOGIN,
  HIDE_LOGIN,
  CLEAR_FILES,
  IS_FILE_UPLOADING,
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import { toastr } from "react-redux-toastr";
import { logout } from "./auth";

// Upload file
export const uploadFile = (form) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const formData = new FormData();
    const encodedFileName = encodeURIComponent(form.filetouploadVM.name);
    formData.append("filetoupload", form.filetouploadVM, encodedFileName);
    formData.append("color_type", form.color_type);
    formData.append("side", form.side);
    formData.append("pages", form.pages);
    formData.append("layout", form.layout);
    formData.append("copies", form.copies);
    formData.append("setConfig", form.setConfig);

    const fileSizeInMegabytes = Math.round(
      form.filetouploadVM.size / 1000000.0
    );
    if (fileSizeInMegabytes > 25) {
      toastr.error(
        "Хуулж болох хамгийн их хэмжээ болох 25MB-ээс хэтэрсэн байна"
      );
    } else {
      dispatch({
        type: IS_FILE_UPLOADING,
        payload: true,
      });
      console.log(form.filetouploadVM.name);
      const res = await axios.post("/api/files/new", formData, config);

      dispatch({
        type: UPLOAD_FILE,
        payload: res.data,
      });
      dispatch({
        type: IS_FILE_UPLOADING,
        payload: false,
      });
      toastr.success("Файлыг амжилттай хууллаа");
    }
  } catch (err) {
    if (
      err.response.status === 401 &&
      err.response.data.msg === "Token is not valid"
    ) {
      window.localStorage.clear("x-auth-token");
      toastr.error("Session expired");
      dispatch(logout());
    }
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => toastr.error(error.msg));
    }
    dispatch({
      type: UPLOAD_FILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    dispatch({
      type: IS_FILE_UPLOADING,
      payload: false,
    });
  }
};

// Upload file anon
export const uploadAnonFile = (form) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const formData = new FormData();
    const encodedFileName = encodeURIComponent(form.filetouploadVM.name);
    formData.append("filetoupload", form.filetouploadVM, encodedFileName);
    formData.append("color_type", form.color_type);
    formData.append("side", form.side);
    formData.append("pages", form.pages);
    formData.append("layout", form.layout);
    formData.append("copies", form.copies);
    formData.append("setConfig", form.setConfig);

    const fileSizeInMegabytes = Math.round(
      form.filetouploadVM.size / 1000000.0
    );
    if (fileSizeInMegabytes > 25) {
      toastr.error(
        "Хуулж болох хамгийн их хэмжээ болох 25MB-ээс хэтэрсэн байна"
      );
    } else {
      dispatch({
        type: IS_FILE_UPLOADING,
        payload: true,
      });
      console.log(form.filetouploadVM.name);
      const res = await axios.post("/api/files/anon", formData, config);

      dispatch({
        type: UPLOAD_FILE,
        payload: res.data,
      });
      dispatch({
        type: IS_FILE_UPLOADING,
        payload: false,
      });
      
      return res.data;
    }
  } catch (err) {
    if (
      err.response.status === 401 &&
      err.response.data.msg === "Token is not valid"
    ) {
      window.localStorage.clear("x-auth-token");
      toastr.error("Session expired");
      dispatch(logout());
    }
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => toastr.error(error.msg));
    }
    dispatch({
      type: UPLOAD_FILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    dispatch({
      type: IS_FILE_UPLOADING,
      payload: false,
    });
  }
};

// Get file by id
export const getFile = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/files/${id}`);

    dispatch({
      type: GET_FILE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get file by id
export const resendQR = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/files/id/resend/${id}`);
    toastr.success("QR code дахин илгээгдлээ");
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => toastr.error(error.msg));
    }
  }
};

// Get all files by user id
export const getFiles = () => async (dispatch) => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const res = await axios.get(`/api/files`);

    dispatch({
      type: GET_FILES,
      payload: res.data,
    });
  } catch (err) {
    if (
      err.response.status === 401 &&
      err.response.data.msg === "Token is not valid"
    ) {
      window.localStorage.clear("x-auth-token");
      toastr.error("Session expired");
      dispatch(logout());
    }
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => toastr.error(error.msg));
    }
  }
};

// Delete post
export const deleteFile = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/files/${id}`);

    dispatch({
      type: DELETE_FILE,
      payload: id,
    });
    toastr.success("Файлыг амжилттай устгалаа");
  } catch (err) {
    if (
      err.response.status === 401 &&
      err.response.data.msg === "Token is not valid"
    ) {
      window.localStorage.clear("x-auth-token");
      toastr.error("Session expired");
      dispatch(logout());
    }
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => toastr.error(error.msg));
    }
  }
};

// Alert set file
export const alertSetFile = () => async (dispatch) => {
  toastr.warning("Файлaa сонгоно уу");
};

// Alert page range
export const pageRangeError = () => async (dispatch) => {};
