import React, { Fragment } from "react";
import spinner from "./spinner.gif";


export default () => (
  <Fragment>
    <img
      src="assets/img/loaders/uploadLoader.gif"
      style={{ width: "100px", margin: "auto", display: "block" }}
      alt="Loading..."
    />
  </Fragment>
);
