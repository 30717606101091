import React, { Fragment, useState } from "react";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login, forgot } from "../../actions/auth";
import Alert from "../layout/Alert";
import Forgot from "./Forgot";

const Login = ({ isShowing, hide, login, forgot, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [isForgot, setIsForgot] = useState(false);
  const toggle = () => {
    setIsForgot(!isForgot);
  };
  const setFalse = () => {
    setIsForgot(false);
  };
  const { email, password } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = async (e) => {
    e.preventDefault();
    if (isForgot) {
      forgot(email);
      setFalse();
      hide();
    } else {
      login(email, password);
    }
  };

  // Redirect if logged in
  if (isAuthenticated) {
    return <Redirect to="/upload" />;
  }

  if (!isShowing) {
    return null;
  }
  return (
    <Fragment>
      <div id="auth-modal" className="modal is-active">
        <div
          className="modal-background"
          onClick={() => {
            setFalse();
            hide();
          }}
        ></div>
        <div className="modal-content">
          <div className="flex-card auth-card">
            <div id="login-tab" className="tab-content">
              <form onSubmit={onSubmit}>
                <div className="field">
                  <label>Емайл</label>
                  <div className="control">
                    <input
                      type="text"
                      className="input is-medium"
                      name="email"
                      placeholder="Емайл оруулна уу"
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
                {isForgot ? (
                  ""
                ) : (
                  <div className="field">
                    <label>Нууц үг</label>
                    <div className="control">
                      <input
                        type="password"
                        className="input is-medium"
                        name="password"
                        placeholder="Нууц үг оруулна уу"
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </div>
                )}

                {isForgot ? (
                  ""
                ) : (
                  <a
                    onClick={() => {
                      toggle();
                    }}
                  >
                    Нууц үгээ мартсан
                  </a>
                )}
                {isForgot ? (
                  <button
                    type="submit"
                    className="button is-fullwidth secondary-btn is-rounded raised"
                  >
                    Сэргээх
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="button is-fullwidth secondary-btn is-rounded raised"
                  >
                    Нэвтрэх
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={() => {
            setFalse();
            hide();
          }}
        ></button>
      </div>
    </Fragment>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login, forgot })(Login);
