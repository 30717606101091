import {
  SHOW_DROPDOWN,
  HIDE_DROPDOWN,
  SHOW_PASS_CHANGE,
  HIDE_PASS_CHANGE,
  SHOW_PROFILE,
  HIDE_PROFILE,
  USER_NOTIFY,
} from "../actions/types";

const initialState = {
  user_dropdown: false,
  change_pass: false,
  show_profile: false,
  user_notify: true,
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_DROPDOWN:
      return {
        ...state,
        user_dropdown: !state.user_dropdown,
        loading: false,
      };
    case HIDE_DROPDOWN:
      return {
        ...state,
        user_dropdown: false,
        loading: false,
      };
    case SHOW_PASS_CHANGE:
      return {
        ...state,
        change_pass: true,
        loading: false,
      };
    case SHOW_PROFILE:
      return {
        ...state,
        show_profile: true,
      };
    case HIDE_PROFILE:
      return {
        ...state,
        show_profile: false,
      };
    case HIDE_PASS_CHANGE:
      return {
        ...state,
        change_pass: false,
        loading: false,
      };
    case USER_NOTIFY:
      return { ...state, user_notify: false, loading: false };
    default:
      return state;
  }
}
