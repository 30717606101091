import React, { Fragment, useState } from "react";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import Alert from "../layout/Alert";


const Forgot = ({ hide, isForgot, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  
  const { email, password } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = async (e) => {
    e.preventDefault();
    login(email, password);
  };

  // Redirect if logged in
  if (isAuthenticated) {
    return <Redirect to="/upload" />;
  }

  if (!isForgot) {
    return null;
  }
  return (
    <Fragment>
      <div id="auth-modal" className="modal is-active">
        <div className="modal-background" onClick={hide}></div>
        <div className="modal-content">
          <div className="flex-card auth-card">
            <div id="login-tab" className="tab-content">
              <form onSubmit={onSubmit}>
                <div className="field">
                  <label>Емайл</label>
                  <div className="control">
                    <input
                      type="text"
                      className="input is-medium"
                      value={email}
                      name="email"
                      placeholder="Емайл оруулна уу"
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
                
                
                <button
                  type="submit"
                  className="button is-fullwidth secondary-btn is-rounded raised"
                >
                  Сэргээх
                </button>
              </form>
            </div>
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={hide}
        ></button>
        <Alert />
      </div>
    </Fragment>
  );
};

Forgot.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Forgot);
