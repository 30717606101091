import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import file from "./file";
import user from "./user";
import { reducer as toastrReducer } from "react-redux-toastr";

export default combineReducers({
  alert,
  auth,
  file,
  user,
  toastr: toastrReducer
});