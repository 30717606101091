import { REGISTER_SUCCESS, REGISTER_FAIL, USER_LOADED, AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, ACCOUNT_DELETED, SHOW_LOGIN, HIDE_LOGIN, CLEAR_PROFILE, UPDATE_PROFILE } from '../actions/types'

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  showLogin: false,
  loading: true,
  user: null,
  userLoading: true
}

export default function(state=initialState, action) {

  const { type, payload } = action

  switch(type) {
    case SHOW_LOGIN:
      return {
        ...state,
        showLogin: true
      }
    case HIDE_LOGIN:
      return {
        ...state,
        showLogin: false
      }
    case UPDATE_PROFILE:
      return {
        ...state,
        user: payload,
        loading: false
      }
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
        userLoading: false
      }
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token)
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false
      }
    case REGISTER_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case CLEAR_PROFILE:
    case LOGOUT:
    case ACCOUNT_DELETED:
      localStorage.removeItem('token')
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        user: null,
        loading: false,
        userLoading: true
      }
    default:
      return state
  }
}
