import axios from "axios";
import { setAlert } from "./alert";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_PROFILE,
  SHOW_LOGIN,
  HIDE_LOGIN,
  CLEAR_FILES,
  FORGOT_PASSWORD
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import { toastr } from "react-redux-toastr";

export const showLogin = () => async (dispatch) => {
  dispatch({
    type: SHOW_LOGIN,
  });
};

export const hideLogin = () => async (dispatch) => {
  dispatch({
    type: HIDE_LOGIN,
  });
};

//  Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    
    const res = await axios.get("/api/auth");
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    console.log(err)
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// REGISTER USER
export const register = ({ name, email, password }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ name, email, password });

  try {
    const res = await axios.post("/api/users", body, config);
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;
    if(errors) {
      errors.forEach(error => toastr.error(error.msg));
    }
    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

// Login USER
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email, password });
  try {
    const res = await axios.post("/api/auth", body, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors;
    
    if(errors) {
      errors.forEach(error => toastr.error(error.msg));
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// Login USER
export const forgot = (email) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email: email.toLowerCase() });
  try {
    const res = await axios.post("/api/auth/forgot", body, config);
    toastr.success('Шинэ нууц үг илгээгдлээ')
    
  } catch (err) {
    const errors = err.response.data.errors;
    if(errors) {
      errors.forEach(error => toastr.error(error.msg));
    }
    
  }
};


// Logout / Clear Profile
export const logout = () => (dispatch) => {
  dispatch({
    type: CLEAR_PROFILE,
  });
  dispatch({
    type: CLEAR_FILES
  })
  dispatch({
    type: LOGOUT,
  });
};
