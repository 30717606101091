export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const GET_REPOS = "GET_REPOS";
export const GET_POSTS = "GET_POSTS";
export const GET_POST = "GET_POST";
export const POST_ERROR = "POST_ERROR";
export const UPDATE_LIKES = "UPDATE_LIKES";
export const DELETE_POST = "DELETE_POST";
export const ADD_POST = "ADD_POST";
export const ADD_COMMENT = "ADD_COMMENT";
export const REMOVE_COMMENT = "REMOVE_COMMENT";
export const CLEAR_FILES = "CLEAR_FILES";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPLOAD_FILE_ERROR = "UPLOAD_FILE_ERROR";
export const GET_FILE = "GET_FILE";
export const FILE_ERROR = "FILE_ERROR";
export const GET_FILES = "GET_FILES";
export const FILES_ERROR = "FILES_ERROR";
export const DELETE_FILE = "DELETE_FILE";
export const SHOW_LOGIN = "SHOW_LOGIN";
export const HIDE_LOGIN = "HIDE_LOGIN";
export const SHOW_DROPDOWN = "SHOW_DROPDOWN";
export const HIDE_DROPDOWN = "HIDE_DROPDOWN";
export const SHOW_PASS_CHANGE = "SHOW_PASS_CHANGE";
export const HIDE_PASS_CHANGE = "HIDE_PASS_CHANGE";
export const IS_FILE_UPLOADING = "IS_FILE_UPLOADING";
export const SHOW_PROFILE = "SHOW_PROFILE";
export const HIDE_PROFILE = "HIDE_PROFILE";
export const USER_NOTIFY = "USER_NOTIFY";
