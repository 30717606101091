import React, { Fragment } from 'react';
import { Link  } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout, showLogin } from '../../actions/auth';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  Appbar: {
    backgroundColor: 'white',
    color: 'black'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  toolbarLogin: {
    marginLeft: 'auto'
  }
}));

const Appbar = ({ auth: { isAuthenticated, loading }, logout, showLogin }) => {

  const classes = useStyles();

  const guestLinks = (<div className={classes.root}>
    <AppBar position="static" className={classes.Appbar}>
      <Toolbar>
          <div className={classes.toolbarLogin}>
            <Button variant="contained" color="primary" onClick={showLogin}>Нэвтрэх</Button>
          </div>

      </Toolbar>
    </AppBar>
  </div>)

  const authLinks = (
    <div className={classes.root}>
      <AppBar position="static" className={classes.Appbar}>
        <Toolbar>
            <div className={classes.toolbarLogin}>
              <Button variant="contained" color="secondary" onClick={logout}>Гарах</Button>
            </div>
        </Toolbar>
      </AppBar>
    </div>
  )

  return(<Fragment>
      { !loading && (<Fragment>{ isAuthenticated ? authLinks : guestLinks }</Fragment>) }
    </Fragment>)
}

Appbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, { logout, showLogin })(Appbar)
