import React, { useEffect, Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { getFiles, deleteFile } from "../../actions/file";
import Navbar from "../layout/Navbar";
import { uploadFile, resendQR, alertSetFile } from "../../actions/file";
import {
  showPassChange,
  hidePassChange,
  changePassword,
  hideProfile,
  updateProfile,
} from "../../actions/user";
import { Link, Events, animateScroll as scroll } from "react-scroll";
import { toastr } from "react-redux-toastr";
import LoadingOverlay from "react-loading-overlay";
import FileConfig from "./FileConfig";
import UserProfile from "../user/UserProfile";
import UserPasswordChange from "../user/UserPasswordChange";
import UserNotifyPoppup from "../user/UserNotifyPoppup";

const FileUpload = ({
  auth: { user, loading },
  getFiles,
  files,
  uploadFile,
  resendQR,
  deleteFile,
  auth: { token, isAuthenticated },
  change_pass,
  uploadingFile,
  hidePassChange,
  changePassword,
  show_profile,
  hideProfile,
  updateProfile,
  userLoading,
  alertSetFile,
  user_notify,
}) => {
  const [formData, setFormData] = useState({
    filetouploadVM: null,
    setConfig: false,
    pages: "all",
    copies: 1,
    side: false,
    color_type: "mono",
    layout: false,
  });

  var { filetouploadVM, setConfig, pages, copies, side, layout, color_type } =
    formData;

  const onChangeFile = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    e.target.value = "";
  };
  const resetField = () => {
    setFormData({ filetouploadVM: null });
    setFormData({
      setConfig: false,
      pages: "all",
      copies: 1,
      side: false,
      color_type: "mono",
      layout: false,
    });
    filetouploadVM = null;
    setConfig = false;
    pages = "all";
    copies = 1;
    side = false;
    color_type = "mono";
    layout = false;
  };
  const displayFiles = files.map((file) => (
    <div class="column is-3">
      <div class="feature-icon has-text-centered">
        <div class="icon-wrap is-icon-reveal">
          <ion-icon name="document" size="large"></ion-icon>
        </div>

        <p class="filename" title={file.initialfilename}>
          <span class="filename-text">{file.initialfilename}</span>
        </p>

        <p>
          {file.date.split("T")[0]} - {file.date.split("T")[1].split(".")[0]}
        </p>
        <div class="button-wrap">
          <a>
            <button
              onClick={() => resendQR(file._id)}
              className="button btn-align-md danger-btn raised"
              style={{
                marginRight: "5px",
                backgroundColor: "#3385ff",
                color: "#FFFFFF",
              }}
            >
              QR илгээх
            </button>
          </a>
          <a>
            <button
              onClick={() => deleteFile(file._id)}
              className="button btn-align-md danger-btn raised"
              style={{ backgroundColor: "#ff471a", color: "#FFFFFF" }}
            >
              Устгах
            </button>
          </a>
        </div>
      </div>
    </div>
  ));

  useEffect(() => {
    getFiles();
  }, [getFiles]);

  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 200) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 200) {
      setShowScroll(false);
    }
  };

  window.addEventListener("scroll", checkScrollTop);
  const [isSetConfig, showIsSetConfig] = useState(false);
  const toggleConfig = () => {
    showIsSetConfig(!isSetConfig);

    if (isSetConfig === true) {
      setFormData({ ...formData, setConfig: false });
    } else {
      setFormData({ ...formData, setConfig: true });
    }
  };
  const [pagesField, setPagesField] = useState(false);
  return (
    <Fragment>
      {!loading && !userLoading && !files.loading ? (
        <Fragment>
          <LoadingOverlay
            active={uploadingFile}
            spinner
            text="Хуулж байна. Түр хүлээнэ үү..."
          >
            <section
              className="hero is-fullheight is-default is-bold"
              id="upload"
              name="upload"
            >
              <Navbar />
              <div className="container">
                <div className="title-wrapper has-text-centered">
                  <h2 className="title is-2">Файл хуулах хэсэг</h2>

                  <div className="divider is-centered"></div>
                </div>

                <div className="content-wrapper">
                  <div className="columns">
                    <div className="column">
                      <div className="card is-bordered has-text-centered is-feature-reveal">
                        <form
                          id="create-course-form"
                          onSubmit={(e) => {
                            e.preventDefault();
                            if (filetouploadVM !== null) {
                              uploadFile(formData);
                              resetField();
                            } else {
                              toastr.warning("Файлаа сонгоно уу!");
                            }
                          }}
                        >
                          <div className="card-header">
                            <h4 className="card-header-title is-centered">
                              ФАЙЛ ХУУЛАХ ХЭСЭГ
                            </h4>
                          </div>
                          <div className="card-icon">
                            <img
                              src="assets/img/icons/upload.png"
                              style={{ maxHeight: "300px" }}
                            />
                          </div>
                          <div className="card-action">
                            <div className="file">
                              <label className="file-label">
                                <input
                                  className="file-input"
                                  type="file"
                                  name="filetouploadVM"
                                  onChange={(e) => {
                                    onChangeFile(e);
                                  }}
                                />
                                <span className="file-cta button is-rounded is-primary">
                                  <span className="file-icon">
                                    <i className="fas fa-upload"></i>
                                  </span>
                                  <span className="file-name">
                                    {filetouploadVM
                                      ? filetouploadVM.name
                                      : "Файл сонгох"}
                                  </span>
                                </span>
                              </label>
                            </div>
                          </div>
                          <br></br>
                          <div className="card-action">
                            <button
                              type="button"
                              className="button btn-align-md primary-btn raised is-rounded"
                              onClick={() => {
                                if (formData.filetouploadVM === null) {
                                  alertSetFile();
                                } else {
                                  toggleConfig();
                                }
                              }}
                            >
                              Хэвлэх тохиргоотой илгээх
                            </button>

                            <br></br>
                            <br></br>
                            <button
                              type="submit"
                              style={{ marginLeft: "5px" }}
                              className="button btn-align-md is-link raised is-rounded"
                            >
                              Илгээх
                            </button>
                          </div>
                          <br></br>
                          <br></br>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </LoadingOverlay>
          <Link
            activeClass="active"
            to="files"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="down-arrow"
            style={{
              position: "fixed",
              bottom: "20px",
              left: "50%",
              marginLeft: "-10px",
              display: showScroll ? "none" : "flex",
            }}
          >
            <i
              className="fas fa-chevron-circle-down"
              style={{ fontSize: "50px" }}
            ></i>
          </Link>

          <section class="section is-medium" id="files" name="files">
            <div class="container">
              <div class="title-wrapper has-text-centered">
                <h2 class="title is-2">Файлууд</h2>

                <div class="divider is-centered"></div>
              </div>

              <div class="content-wrapper">
                <div class="columns is-multiline icon-list">{displayFiles}</div>
              </div>
            </div>
          </section>
          <FileConfig
            isSetConfig={isSetConfig}
            toggleConfig={toggleConfig}
            showIsSetConfig={showIsSetConfig}
            formData={formData}
            setFormData={setFormData}
            pagesField={pagesField}
            setPagesField={setPagesField}
            uploadFile={uploadFile}
          />
          <UserNotifyPoppup isShowing={user_notify} />
          <UserPasswordChange
            isShowing={change_pass}
            hidePassChange={hidePassChange}
            changePassword={changePassword}
          ></UserPasswordChange>
          <UserProfile
            isShowing={show_profile}
            hideProfile={hideProfile}
            user={user}
            updateProfile={updateProfile}
          ></UserProfile>
        </Fragment>
      ) : (
        <Spinner></Spinner>
      )}
    </Fragment>
  );
};

FileUpload.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  files: state.file.files,
  user_dropdown: state.user.user_dropdown,
  user_notify: state.user.user_notify,
  change_pass: state.user.change_pass,
  uploadingFile: state.file.uploadingFile,
  show_profile: state.user.show_profile,
  userLoading: state.auth.userLoading,
});

export default connect(mapStateToProps, {
  getFiles,
  uploadFile,
  deleteFile,
  resendQR,
  showPassChange,
  hidePassChange,
  changePassword,
  hideProfile,
  updateProfile,
  alertSetFile,
})(FileUpload);
