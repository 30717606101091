import axios from "axios";
import { setAlert } from "./alert";
import {
  SHOW_DROPDOWN,
  HIDE_DROPDOWN,
  SHOW_PASS_CHANGE,
  HIDE_PASS_CHANGE,
  SHOW_PROFILE,
  HIDE_PROFILE,
  UPDATE_PROFILE,
  USER_NOTIFY,
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import { toastr } from "react-redux-toastr";

export const showDropDown = () => async (dispatch) => {
  dispatch({
    type: SHOW_DROPDOWN,
  });
};

export const hideDropDown = () => async (dispatch) => {
  dispatch({
    type: HIDE_DROPDOWN,
  });
};

export const showPassChange = () => async (dispatch) => {
  dispatch({
    type: SHOW_PASS_CHANGE,
  });
};

export const userNotify = () => async (dispatch) => {
  dispatch({
    type: USER_NOTIFY,
  });
};

export const hidePassChange = () => async (dispatch) => {
  dispatch({
    type: HIDE_PASS_CHANGE,
  });
};

export const showProfile = () => async (dispatch) => {
  dispatch({
    type: SHOW_PROFILE,
  });
};

export const hideProfile = () => async (dispatch) => {
  dispatch({
    type: HIDE_PROFILE,
  });
};

// Change USER Password
export const changePassword = (password, newpassword) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ password, newpassword });

  try {
    const res = await axios.post("/api/users/change", body, config);
    dispatch({ type: HIDE_PASS_CHANGE });
    toastr.success("Нууц үг шинэчлэгдлээ");
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => toastr.error(error.msg));
    }
  }
};

// Update Profile
export const updateProfile = (form) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(form);

  try {
    const res = await axios.post("/api/users/profile", body, config);
    dispatch({ type: UPDATE_PROFILE, payload: res.data });
    dispatch({ type: HIDE_PROFILE });
    toastr.success("Хадгалагдсан");
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => toastr.error(error.msg));
    }
  }
};
