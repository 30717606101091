import React, { useEffect, Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Spinner from '../layout/Spinner'
import { Link } from 'react-router-dom'
import { uploadFile } from '../../actions/file';
import Login from '../auth/Login';
import { showLogin, hideLogin } from '../../actions/auth';
import FileUpload from '../file/FileUpload';

const Dashboard = ({ auth: { user, showLogin, isAuthenticated }, uploadFile }) => {
  const [formData, setFormData] = useState({
    filetoupload: null
  });
  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.files[0] })
  const { filetoupload } = formData;
  return (
    <Fragment>
      {(showLogin && !isAuthenticated) ? <Login /> : <FileUpload />}
    </Fragment>
  )
}

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth,
})

export default connect(mapStateToProps, { uploadFile })(Dashboard)
