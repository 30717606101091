import React, { useEffect, Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { userNotify } from "../../actions/user";

const UserNotifyPoppup = ({ isShowing, userNotify }) => {
  if (!isShowing) {
    return null;
  }
  return (
    <Fragment>
      <div className="modal is-active">
        <div
          className="modal-background"
          onClick={() => {
            userNotify();
          }}
        ></div>
        <div className="modal-content">
          <div className="flex-card auth-card">
            <div className="tab-content">
              <p>
                Системийн шинэчлэлттэй холбоотойгоор файлуудыг 72 цагаас дээш
                хугацаанд хадгалaхгүй мөн 5-aaс дээш файл хуулахгүй болсныг
                анхаарна уу.
              </p>
              <button
                type="button"
                onClick={userNotify}
                className="button is-fullwidth secondary-btn is-rounded raised"
              >
                OK
              </button>
            </div>
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={userNotify}
        ></button>
      </div>
    </Fragment>
  );
};

UserNotifyPoppup.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user_notify: state.user.user_notify,
});

export default connect(mapStateToProps, { userNotify })(UserNotifyPoppup);
