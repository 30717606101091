import React, { Fragment, useState } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { setAlert } from '../../actions/alert'
import { register } from '../../actions/auth'
import PropTypes from 'prop-types'
import Alert from '../layout/Alert';
import { toastr } from 'react-redux-toastr'

const Register = ({ isShowingRegister, hideRegister, setAlert, register, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password2: ''
  });

  const { name, email, password, password2 } = formData
  const onChange = e => setFormData ({ ...formData, [e.target.name]: e.target.value })
  const onSubmit = async e => {
    e.preventDefault()
    if(password !== password2) {
    toastr.error('Нууц үгүүд хоорондоо таарсангүй')
    } else {
      register({ name, email, password })
    }
  }

  if(isAuthenticated) {
    return <Redirect to='/upload' />
  }
  if(!isShowingRegister) {
    return null
  }

  return (
    <Fragment>
      <div id="auth-modal" className="modal is-active">
            <div className="modal-background" onClick={hideRegister}></div>
            <div className="modal-content">
                <div className="flex-card auth-card">
                        <div id="login-tab" className="tab-content">
                          <form onSubmit={onSubmit}>
                            <div className="field">
                                <div className="control">
                                    <input type="text" className="input is-medium" value={name} name="name" placeholder="Нэр" onChange={e=> onChange(e)}/>
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <input type="text" className="input is-medium" value={email} name="email" placeholder="Емайл хаяг" onChange={e=> onChange(e)}/>
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <input type="password" className="input is-medium" name="password" value={password} placeholder="Нууц үг" onChange={e=> onChange(e)}/>
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <input type="password" className="input is-medium" name="password2" value={password2} placeholder="Нууц үгээ давтана уу" onChange={e=> onChange(e)}/>
                                </div>
                            </div>
                            <button type="submit" className="button is-fullwidth secondary-btn is-rounded" >Бүртгүүлэх</button>
                            </form>
                        </div>

                </div>
            </div>
            <button className="modal-close is-large" aria-label="close" onClick={hideRegister}></button>
            <Alert />
        </div>
    </Fragment>
  )
}

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
})
export default connect(mapStateToProps, { setAlert, register })(Register)
