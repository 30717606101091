import React, { useEffect, Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import {
  isShowing,
  hidePassChange,
  changePassword,
  showPassChange,
} from "../../actions/user";

const UserPasswordChange = ({ isShowing, hidePassChange, changePassword }) => {
  const [formData, setFormData] = useState({
    password: "",
    newpassword1: "",
    newpassword2: "",
  });
  if (!isShowing) {
    return null;
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    if (newpassword1 !== newpassword2) {
      toastr.error("Шинэ нууц үг хоорондоо таарахгүй байна");
    } else {
      changePassword(password, newpassword1);
    }
  };
  const { password, newpassword1, newpassword2 } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  return (
    <Fragment>
      <div className="modal is-active">
        <div
          className="modal-background"
          onClick={() => {
            hidePassChange();
          }}
        ></div>
        <div className="modal-content">
          <div className="flex-card auth-card">
            <div className="tab-content">
              <form onSubmit={onSubmit}>
                <div className="field">
                  <div className="control">
                    <input
                      type="password"
                      className="input is-medium"
                      name="password"
                      onChange={(e) => onChange(e)}
                      placeholder="Хуучин нууц үг"
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <input
                      type="password"
                      className="input is-medium"
                      name="newpassword1"
                      onChange={(e) => onChange(e)}
                      placeholder="Шинэ нууц үг"
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <input
                      type="password"
                      className="input is-medium"
                      name="newpassword2"
                      onChange={(e) => onChange(e)}
                      placeholder="Шинэ нууц үгээ давтана уу"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="button is-fullwidth secondary-btn is-rounded raised"
                >
                  Солих
                </button>
              </form>
            </div>
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={hidePassChange}
        ></button>
      </div>
    </Fragment>
  );
};

UserPasswordChange.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  change_pass: state.user.change_pass,
  userLoading: state.auth.userLoading,
});

export default connect(mapStateToProps, {
  showPassChange,
  hidePassChange,
  changePassword,
})(UserPasswordChange);
