import React, { useEffect, Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getFiles, deleteFile } from "../../actions/file";
import { uploadFile, resendQR, pageRangeError } from "../../actions/file";
import {
  showPassChange,
  hidePassChange,
  changePassword,
  showProfile,
  hideProfile,
  updateProfile,
} from "../../actions/user";

const FileConfig = ({
  isSetConfig,
  toggleConfig,
  showIsSetConfig,
  formData,
  setFormData,
  pagesField,
  setPagesField,
  uploadFile,
}) => {
  if (!isSetConfig) {
    return null;
  }

  var pagesFieldValue = false;

  const onSubmit = async (e) => {
    e.preventDefault();
    uploadFile(formData);
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onChangePages = (e) => {
    if (e.target.value === "false") {
      pagesFieldValue = false;
      setFormData({ ...formData, setConfig: true });
      setPagesField(false);
      setFormData({ ...formData, [e.target.name]: "all" });
    } else {
      setFormData({ ...formData, setConfig: true });
      pagesFieldValue = true;
      setPagesField(true);
    }
  };

  return (
    <Fragment>
      <div className="modal is-active">
        <div
          className="modal-background"
          onClick={() => {
            toggleConfig();
            setPagesField(false);
            pagesFieldValue = false;
          }}
        ></div>
        <div className="modal-content">
          <div className="flex-card auth-card">
            <div className="tab-content">
              <form onSubmit={onSubmit}>
                <div className="field is-horizontal">
                  <div className="field-label has-text-left">
                    <label className="label">Pages</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="select is-fullwidth is-medium">
                        <select name="pages" onChange={(e) => onChangePages(e)}>
                          <option value="false" selected>
                            Бүх хуудас
                          </option>
                          <option value="true">Хуудас сонгох</option>
                        </select>
                      </div>

                      <input
                        type="text"
                        className={
                          pagesField
                            ? "input hidden-pages-field"
                            : "input hidden-pages-field is-hidden"
                        }
                        name="pages"
                        onChange={(e) => onChange(e)}
                        placeholder="жишээ нь: 1-5, 8, 11-13"
                      />
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label has-text-left	">
                    <label className="label">Copies</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <input
                        type="text"
                        className="input is-medium"
                        name="copies"
                        onChange={(e) => onChange(e)}
                        placeholder="1"
                      />
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label has-text-left">
                    <label className="label">Color</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="select is-fullwidth is-medium">
                        <select name="color_type" onChange={(e) => onChange(e)}>
                          <option value="false" selected>
                            Хар
                          </option>
                          <option value="true">Өнгөт</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label has-text-left">
                    <label className="label">Layout</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="select is-fullwidth is-medium">
                        <select name="layout" onChange={(e) => onChange(e)}>
                          <option value="false" selected>
                            Босоо
                          </option>
                          <option value="true">Хөндлөн</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label has-text-left">
                    <label className="label">Side</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="select is-fullwidth is-medium">
                        <select name="side" onChange={(e) => onChange(e)}>
                          <option value="false" selected>
                            Нэг талдаа
                          </option>
                          <option value="true">Хоёр талдаа</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-grouped is-grouped-right">
                  <p className="control">
                    <button
                      type="submit"
                      className="button btn-align-md is-link raised is-rounded"
                    >
                      Илгээх
                    </button>
                  </p>
                  <p className="control">
                    <button
                      type="button"
                      className="button btn-align-md is-danger raised is-rounded"
                      onClick={() => {
                        showIsSetConfig(!isSetConfig);
                        setPagesField(false);
                        pagesFieldValue = false;
                      }}
                    >
                      Хаах
                    </button>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

FileConfig.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  files: state.file.files,
  user_dropdown: state.user.user_dropdown,
  change_pass: state.user.change_pass,
  uploadingFile: state.file.uploadingFile,
  show_profile: state.user.show_profile,
  userLoading: state.auth.userLoading,
});

export default connect(mapStateToProps, {
  getFiles,
  uploadFile,
  deleteFile,
  resendQR,
  showPassChange,
  hidePassChange,
  changePassword,
  showProfile,
  hideProfile,
  updateProfile,
})(FileConfig);
