import React, { useEffect, Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { showProfile, hideProfile, updateProfile } from "../../actions/user";

const UserProfile = ({ isShowing, hideProfile, user, updateProfile }) => {
  const [formData, setFormData] = useState({
    surname: "",
    givenname: "",
    phone: "",
  });

  useEffect(() => {
    setFormData({
      surname: !user.surname ? "" : user.surname,
      givenname: !user.givenname ? "" : user.givenname,
      phone: !user.phone ? "" : user.phone,
    });
  }, []);

  if (!isShowing) {
    return null;
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    updateProfile(formData);
  };
  const { surname, givenname, phone } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  return (
    <Fragment>
      <div className="modal is-active">
        <div
          className="modal-background"
          onClick={() => {
            hideProfile();
          }}
        ></div>
        <div className="modal-content">
          <div className="flex-card auth-card">
            <div className="tab-content">
              <form onSubmit={onSubmit}>
                <div className="field">
                  <div className="control">
                    <label>Овог</label>
                    <input
                      type="text"
                      className="input is-medium"
                      name="surname"
                      value={surname}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <label>Нэр</label>
                    <input
                      type="text"
                      className="input is-medium"
                      name="givenname"
                      value={givenname}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <label>Утас</label>
                    <input
                      type="text"
                      className="input is-medium"
                      name="phone"
                      value={phone}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="button is-fullwidth secondary-btn is-rounded raised"
                >
                  Хадгалах
                </button>
              </form>
            </div>
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={hideProfile}
        ></button>
      </div>
    </Fragment>
  );
};

UserProfile.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  change_pass: state.user.change_pass,
  uploadingFile: state.file.uploadingFile,
  show_profile: state.user.show_profile,
  userLoading: state.auth.userLoading,
});

export default connect(mapStateToProps, {
  showProfile,
  hideProfile,
  updateProfile,
})(UserProfile);
