import React, { Fragment, useState, useRef } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Navbar from "./Navbar";
import Login from "../auth/Login";
import Register from "../auth/Register";
import { uploadAnonFile } from "../../actions/file";
import { toastr } from "react-redux-toastr";

const Landing = ({ isAuthenticated, uploadAnonFile }) => {
  const [isShowingLogin, setIsShowingLogin] = useState(false);
  const toggle = () => {
    setIsShowingLogin(!isShowingLogin);
  };
  const [isShowingRegister, setIsShowingRegister] = useState(false);
  const toggleRegister = () => {
    setIsShowingRegister(!isShowingRegister);
  };
  const [isActive, setisActive] = useState(false);
  
  const [formData, setFormData] = useState({
    filetouploadVM: null,
    setConfig: false,
    pages: "all",
    copies: 1,
    side: false,
    color_type: "mono",
    layout: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const canvasRef = useRef(null);
  const [qrCodeData, setQrCodeData] = useState(null); // State for QR code data
  const [isModalActive, setIsModalActive] = useState(false); // State for modal visibility

  var { filetouploadVM, setConfig, pages, copies, side, layout, color_type } =
    formData;

  const onChangeFile = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    e.target.value = "";
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const resetField = () => {
    setFormData({
      filetouploadVM: null,
      setConfig: false,
      pages: "all",
      copies: 1,
      side: false,
      color_type: "mono",
      layout: false,
    });
  };
  
  const downloadQRCode = () => {
    const canvas = canvasRef.current;
    const img = new Image();
    img.src = qrCodeData;
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      const downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "QRCode.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };
  };


  const handleUpload = async (e) => {
    e.preventDefault();
    if (formData.filetouploadVM !== null) {
      setIsLoading(true); // Show loading spinner
      try {
        setIsModalActive(true); // Show the modal with the QR code
        const response = await uploadAnonFile(formData);
        if (response && response.qr) {
          setQrCodeData(response.qr); // Store the QR code data
        }
        resetField();
      } catch (error) {
        toastr.error("Файл хуулахад алдаа гарлаа.");
      } finally {
        setIsLoading(false); // Hide loading spinner
      }
    } else {
      toastr.warning("Файлаа сонгоно уу!");
    }
  };
  
  

  const closeModal = () => {
    setIsModalActive(false);
    setQrCodeData(null);
    window.location.reload(); // This will refresh the window
  };

  if (isAuthenticated) {
    return <Redirect to="/upload" />;
  }

  return (
    <Fragment>
      <section className="hero is-fullheight is-default is-bold">
        <Navbar />
        <div className="hero-body">
          <div className="container">
            <div className="columns is-vcentered is-variable is-8">
              <Navbar />
              <div className="container">
              <div className="column is-8 mt-6">
                <div className="content-wrapper">
                  <div className="columns">
                    <div className="column">
                      <div className="card is-bordered has-text-centered is-feature-reveal">
                        <form
                          id="create-course-form"
                          onSubmit={handleUpload}
                        >
                          <div className="card-header">
                            <h4 className="card-header-title is-size-5 is-centered">
                             Шууд файлаа илгээе
                            </h4>
                          </div>
                          <div className="card-icon">
                            <img
                              src="assets/img/icons/upload.png"
                              style={{ maxHeight: "300px" }}
                            />
                          </div>
                          <div className="card-action">
                            <div className="file">
                              <label className="file-label">
                                <input
                                  className="file-input"
                                  type="file"
                                  name="filetouploadVM"
                                  onChange={(e) => {
                                    onChangeFile(e);
                                  }}
                                />
                                <span className="file-cta button is-rounded is-primary">
                                  <span className="file-icon">
                                    <i className="fas fa-upload"></i>
                                  </span>
                                  <span className="file-name">
                                    {filetouploadVM
                                      ? filetouploadVM.name
                                      : "Файл сонгох"}
                                  </span>
                                </span>
                              </label>
                            </div>
                          </div>
                          <br></br>
                          <div className="card-action">
                            
                            <button
                              type="submit"
                              style={{ marginLeft: "5px" }}
                              className="button btn-align-md is-link raised is-rounded"
                            >
                              Илгээх
                            </button>
                          </div>
                          <br></br>
                          <br></br>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div className="column is-5 mt-6">
                <figure className="image">
                  <img
                    src="assets/img/illustrations/printer.png"
                    alt="Description"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Modal for QR Code */}
      {isModalActive && (
        <div className={`modal ${isModalActive ? 'is-active' : ''}`}>
          <div className="modal-background" onClick={closeModal}></div>
          <div className="modal-content">
            <div className="box has-text-centered modal-box">
              {isLoading ? (
                <div>
                  <p className="title">Хуулж байна...</p>
                  <div className="loading-gif-container">
                    <img src="assets/img/loaders/uploadLoader.gif" width={"200px"} alt="Loading" className="loading-gif" />
                  </div>
                </div>
              ) : (
                <div>
                  <p className="title">Файл амжилттай хуулагдлаа</p>
                  <p>Та QR кодыг киосконд уншуулна уу.</p>
                  <div className="qr-code-container">
                    <img src={qrCodeData} alt="QR Code" className="qr-code-image" />
                    <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
                  </div>
                  <div className="has-text-centered mt-4">
                    <button
                      onClick={downloadQRCode}
                      className="button is-info is-rounded is-small"
                    >
                      <span className="icon is-small">
                        <i className="fas fa-download"></i>
                      </span>
                      <span>QR код татах</span>
                    </button>
                  </div>
                  <br></br>
                  <p>Та QR кодоо хадгалж авна уу. Таны QR код 24 цагийн турш хүчинтэй!</p>
                  <p>Бүртгэлтэй эрхээрээ нэвтрээд оруулсан файлын QR код 72 цагийн турш хүчинтэй!</p>
                </div>
              )}
            </div>
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={closeModal}
          ></button>
        </div>
      )}


      <section className="section is-medium">
        <div className="container">
          <div className="title-wrapper has-text-centered">
            <h2 className="title is-2">Ниргэ Системс</h2>
            <h3 className="subtitle is-5 is-muted">Now you see your future</h3>
            <div className="divider is-centered"></div>
          </div>
          <div className="columns is-vcentered side-feature mt-60">
            <div className="column is-4 is-offset-2">
              <h3 className="title is-3 mb-10 is-title-reveal">CLOUD PRINT</h3>
              <p className="subtitle is-5 is-muted">
                Монголын хамгийн анхны <strong>Cloud</strong> дээр суурилсан
                өөртөө үйлчлэх хэвлэлийн төхөөрөмж
              </p>
            </div>
            <div className="column is-5">
              <img src="assets/img/art.png" alt="" />
            </div>
          </div>
          <div className="columns is-vcentered side-feature mt-60">
            <div className="column is-5 is-offset-1">
              <img src="assets/img/illustrations/nirge.png" alt="" />
            </div>
            <div className="column is-4 is-offset-1">
              <h3 className="title is-3 mb-10 is-title-reveal">Ниргэ Системс</h3>
              <p className="subtitle is-5 is-muted">
                Монголынхоо мэдээлэл технологийн хөгжилд хувь нэмэрээ оруулахыг
                эрмэлзэж буй залуучуудын нэгдэл юм.
              </p>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer footer-grey">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="footer-logo">
                <img
                  src="assets/img/cloudlogo.png"
                  style={{
                    width: "auto",
                    height: "100px",
                  }}
                  alt="Cloud Logo"
                />
              </div>
            </div>
            <div className="column">
              <div className="footer-column">
                <div className="footer-header">
                  <h3>CLOUD PRINT байршил</h3>
                </div>
                <p>
                  Улаанбаатар, Сүхбаатар дүүрэг, Бага тойруу, Их сургуулийн
                  гудамж, МУИС-ийн номын сангийн байр
                </p>
              </div>
            </div>
            <div className="column">
              <div className="footer-column">
                <div className="footer-header">
                  <h3>Холбоо барих</h3>
                </div>
                <p>Утас: 88844114</p>
                <p>Емайл: nirgesystems@gmail.com</p>
              </div>
            </div>
            <div className="column">
              <div className="footer-column">
                <div className="footer-header">
                  <h3>Follow Us</h3>
                  <nav className="level is-mobile">
                    <div className="level-left">
                      <a
                        className="level-item"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/Nirgesystems"
                      >
                        <span className="">
                          <ion-icon
                            name="logo-facebook"
                            size="large"
                          ></ion-icon>
                        </span>
                      </a>
                      <a
                        className="level-item"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.instagram.com/nirgesystemsmongolia/"
                      >
                        <span className="">
                          <ion-icon
                            name="logo-instagram"
                            size="large"
                          ></ion-icon>
                        </span>
                      </a>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <Login isShowing={isShowingLogin} hide={toggle} />
      <Register isShowingRegister={isShowingRegister} hideRegister={toggleRegister} />
    </Fragment>
  );
};

Landing.propTypes = {
  isAuthenticated: PropTypes.bool,
  uploadAnonFile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { uploadAnonFile })(Landing);
